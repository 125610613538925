import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import { Section } from "@components/StyledComponents";
import ListingsGallery from "@components/ListingsGallery";

const ListingsPage = ({ data }) => {
  const header = data.header.childImageSharp.fluid;
  const pdfs = data.pdfs.edges;
  const images = data.images.edges;
  return (
    <Layout
      seoTitle="Active Listins"
      seoDescription="Active Listings For Cassandra Lee & Co"
      fluid={header}
      alt="Active Listings For Cassandra Lee & Co"
      headerTitle="Buy.Sell.Invest"
      headerSubTitle="Your home is my commitment"
    >
      <Section>
        <h2>Active Listings</h2>
        <hr />
        <ListingsGallery pdfs={pdfs} images={images} />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query ListingssQuery {
    header: file(relativePath: { eq: "unsplash_1_1200x798.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pdfs: allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "listings" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
  }
`;

export default ListingsPage;
