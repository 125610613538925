import React from "react";
import Img from "gatsby-image";
import Styled from "styled-components";

const Container = Styled.div`
    background-color: ${({ theme }) => theme.gainsboro};
    padding: 10px;
    place-self: stretch;
    font-family: ${({ theme }) => theme.fontSecondary};
    cursor: pointer;
    transition: all 250ms linear;
    &:hover {
        transform: scale(1.05);
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.5);
    }
    h4 {
        margin: 0px;
    }
    a {
        text-decoration: none;
        color: ${({ theme }) => theme.black};
        &:hover {
            color: ${({ theme }) => theme.action};
            text-decoration: underline;
        }
    }
`;
const Thumbnail = Styled(Img)`
    max-width: 900px;
`;

const GalleryItem = ({ name, publicURL, listing, fluid }) => (
  <Container>
    <Thumbnail fluid={fluid} alt={name} />
    <h3>{listing.price}</h3>
    <h4>{listing.address}</h4>
    <h4>
      {listing.city}, {listing.state} {listing.zip}
    </h4>
    <h3>MLS: {name}</h3>
    <a href={publicURL} download>
      Download Listing PDF
    </a>
  </Container>
);

export default GalleryItem;
