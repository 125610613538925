import React from "react";
import { v4 as uuidv4 } from "uuid";
import Styled from "styled-components";

import GalleryItem from "./GalleryItem";
import { LISTINGS } from "@config";

const Container = Styled.div`
    margin: 2rem;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
`;

const ListingsGallery = ({ pdfs, images }) => {
  const renderGallery = () => {
    const gallery = [];
    for (let i = 0; i < pdfs.length; i++) {
      const { name, publicURL } = pdfs[i].node;
      for (let j = 0; j < images.length; j++) {
        if (images[j].node.name === name) {
          const listing = LISTINGS[name];
          const fluid = images[j].node.childImageSharp.fluid;
          gallery.push(<GalleryItem key={uuidv4()} name={name} publicURL={publicURL} listing={listing} fluid={fluid} />);
        }
      }
    }
    return <>{gallery}</>;
  };
  return <Container>{renderGallery()}</Container>;
};

export default ListingsGallery;
